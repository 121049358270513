import React from 'react';
import { Link } from 'gatsby';
import Img from 'gatsby-image';
import styled, { css } from 'styled-components';
import { up, down } from 'styled-breakpoints';

import useBreakpoint from '@babelcoder/gatsby-theme-base/src/hooks/useBreakpoint';
import numToPrice from '../../../utils/numToPrice';

const CourseLink = styled(Link)`
  ${({ theme }) => css`
    box-shadow: 0px 0px 5px 0px ${theme.colors.neutral.gray400};
    padding: 1rem;

    border-radius: ${theme.round.xlarge};
    background-color: ${theme.colors.neutral.white};
  `}
`;

const Container = styled.article`
  /* ${up('medium')} {
    display: grid;
    grid-template:
      'image' auto
      'title' auto
      'excerpt' auto
      'spacer' 1fr
      'price' auto
      'footer' auto / auto;
    height: 100%;

    ${up('xlarge')} {
      grid-template:
        'image title' auto
        'image excerpt' auto
        'spacer spacer' 1fr
        'price price' auto
        'footer footer' auto / auto 1fr;
    }
  } */
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const Spacer = styled.div`
  ${({ theme }) => css`
    /* grid-area: spacer; */
    margin: ${theme.spacers.normal} 0;
  `}
`;

const CourseImageWrapper = styled(Img)`
  ${({ theme }) => css`
    /* grid-area: image; */

    /* ${up('xlarge')} {
      margin-right: ${theme.spacers.normal};
    } */
  `}
`;

const StyledCourseImage = styled(Img)`
  width: 100%;
  box-shadow: 0px 0px 5px 0px ${({ theme }) => theme.colors.neutral.gray300};
`;

const Title = styled.h2`
  /* ${({ theme }) => css`
    grid-area: title;
    margin: 0;
    font-size: ${theme.fontSizes.normal};
    color: ${theme.colors.neutral.gray1200};

    ${down('large')} {
      font-size: ${theme.fontSizes.medium};
      margin: ${theme.spacers.normal} 0;
    }
  `} */
  ${({ theme }) => css`
    /* grid-area: title; */
    color: ${theme.colors.neutral.gray1200};
    font-size: ${theme.fontSizes.medium};
    line-height: 1.5;
    margin: ${theme.spacers.normal} 0;
  `}
`;

const Excerpt = styled.p`
  /* ${({ theme }) => css`
    grid-area: excerpt;
    margin: 0;
    color: ${theme.colors.neutral.gray1100};
    font-size: ${theme.fontSizes.small};

    ${down('large')} {
      font-size: ${theme.fontSizes.normal};
    }
  `} */
  ${({ theme }) => css`
    /* grid-area: excerpt; */
    margin: 0;
    color: ${theme.colors.neutral.gray1100};
    font-size: ${theme.fontSizes.normal};
  `}
`;

const CourseFooter = styled.footer`
  ${({ theme }) => css`
    /* grid-area: footer; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: ${theme.spacers.normal};
    border-top: 1px solid ${theme.colors.neutral.gray200};
  `}
`;

const Author = styled.div`
  display: flex;
  align-items: center;
`;

const AuthorImage = styled(Img)`
  border-radius: 1rem;
  box-shadow: 0px 0px 8px 0px ${({ theme }) => theme.colors.neutral.gray600};
`;

const AuthorName = styled.div`
  ${({ theme }) => css`
    color: ${theme.colors.neutral.gray800};
    margin-left: ${theme.spacers.normal};
    font-size: ${theme.fontSizes.small};
  `}
`;

const PriceArea = styled.div`
  ${({ theme }) => css`
    /* grid-area: price; */
    display: grid;
    grid-template-areas:
      'liveDesc livePrice'
      'liveTime liveTime'
      'videoDesc videoPrice';
    align-items: center;
    padding: 0.5rem;
    color: ${theme.colors.neutral.gray1000};
    border-top: 1px solid ${theme.colors.neutral.gray200};
    margin-top: auto;

    /* ${up('large')} {
      padding-left: 0;
      padding-right: 0;
    } */
  `}
`;

const LivePriceDesc = styled.div`
  grid-area: liveDesc;

  /* ${up('large')} {
    padding-left: 0;
  } */
`;

const LivePrice = styled.div`
  ${({ theme }) => css`
    grid-area: livePrice;
    font-family: ${theme.fontFamily.heading};
    align-self: flex-start;
    justify-self: end;

    /* ${up('large')} {
      padding-left: 0;
    } */
  `}
`;

const LiveTime = styled.div`
  ${({ theme }) => css`
    grid-area: liveTime;
    line-height: 0.5;
    padding-bottom: 1rem;
    font-style: italic;
    font-size: ${theme.fontSizes.small};
    color: ${theme.colors.neutral.gray900};
  `}
`;

const VideoPriceDesc = styled.div`
  grid-area: videoDesc;

  /* ${up('large')} {
    padding-left: 0;
  } */
`;

const VideoPrice = styled.div`
  ${({ theme }) => css`
    grid-area: videoPrice;
    font-family: ${theme.fontFamily.heading};
    justify-self: end;

    /* ${up('large')} {
      padding-left: 0;
    } */
  `}
`;

function CourseImage({ desktopImage, mobileImage }) {
  // const size = useBreakpoint();
  // const isFixed = size === 'xlarge';
  // const props = isFixed
  //   ? { fixed: desktopImage.childImageSharp.fixed }
  //   : { fluid: mobileImage.childImageSharp.fluid };
  const props = { fluid: mobileImage.childImageSharp.fluid };

  return (
    // <CourseImageWrapper as={isFixed ? 'div' : 'span'}>
    //   <StyledCourseImage {...props}></StyledCourseImage>
    // </CourseImageWrapper>
    <CourseImageWrapper as="span">
      <StyledCourseImage {...props}></StyledCourseImage>
    </CourseImageWrapper>
  );
}

function CourseItem({
  creatorImage,
  course: {
    slug,
    title,
    excerpt,
    livePrice,
    videoPrice,
    liveTime,
    desktopImage,
    mobileImage,
  },
}) {
  return (
    <CourseLink to={`/courses/${slug}/`}>
      <Container>
        <CourseImage
          desktopImage={desktopImage}
          mobileImage={mobileImage}
        ></CourseImage>
        <Title>{title}</Title>
        <Excerpt>{excerpt}</Excerpt>
        <Spacer></Spacer>
        <PriceArea>
          <LivePriceDesc>รอบสอนสดออนไลน์</LivePriceDesc>
          <LivePrice>{numToPrice(livePrice)} ฿</LivePrice>
          <LiveTime>(วันอบรม {liveTime})</LiveTime>
          <VideoPriceDesc>วิดีโอบันทึกการสอน</VideoPriceDesc>
          <VideoPrice>{numToPrice(videoPrice)} ฿</VideoPrice>
        </PriceArea>
        <CourseFooter>
          <Author>
            <AuthorImage fixed={creatorImage.fixed}></AuthorImage>
            <AuthorName>Nuttavut Thongjor</AuthorName>
          </Author>
        </CourseFooter>
      </Container>
    </CourseLink>
  );
}

export default CourseItem;
