import React from 'react'
import { Link } from 'gatsby'
import styled, { css } from 'styled-components'

const PageList = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  margin: ${({ theme }) => theme.spacers.normal} 0;
`

const PageItem = styled(Link)`
  ${({ theme, $isCurrentPage }) => css`
    width: calc(${theme.spacers.normal} * 2);
    height: calc(${theme.spacers.normal} * 2);
    border-radius: 50%;
    text-align: center;

    color: ${theme.colors.neutral.gray900};
    background-color: ${theme.colors.neutral.gray300};
    margin-right: 0.5rem;

    &:hover {
      color: ${theme.colors.neutral.white};
      background-color: ${theme.colors.main.secondary};
    }

    ${$isCurrentPage &&
    css`
      color: ${theme.colors.neutral.white};
      background-color: ${theme.colors.main.primary};
    `}
  `}
`

function Pagination({ basePath, currentPage = 1, totalPages }) {
  return (
    <PageList>
      {Array.from({ length: totalPages }).map((_, i) => {
        const page = i + 1
        const target = page === 1 ? basePath : `${basePath}/p/${page}`

        return (
          <PageItem key={i} to={target} $isCurrentPage={currentPage === page}>
            {page}
          </PageItem>
        )
      })}
    </PageList>
  )
}

export default Pagination
