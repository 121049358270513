import { useState, useEffect } from 'react'
import throttle from 'lodash.throttle'

import { breakpoints } from '../theme/base'

const getDeviceConfig = (width) => {
  const { xlarge, large, medium, small } = breakpoints

  if (width >= xlarge) {
    return 'xlarge'
  } else if (width >= large) {
    return 'large'
  } else if (width >= medium) {
    return 'medium'
  } else if (width >= small) {
    return 'small'
  } else {
    return 'xsmall'
  }
}

const useBreakpoint = () => {
  const [brkPnt, setBrkPnt] = useState(() =>
    getDeviceConfig(typeof window === 'undefined' ? 0 : window.innerWidth)
  )

  useEffect(() => {
    const calcInnerWidth = throttle(function () {
      setBrkPnt(getDeviceConfig(window.innerWidth))
    }, 200)
    window.addEventListener('resize', calcInnerWidth)
    return () => window.removeEventListener('resize', calcInnerWidth)
  }, [])

  return brkPnt
}
export default useBreakpoint
