import styled, { css } from 'styled-components'

const Header = styled.h1`
  ${({ theme }) => css`
    position: relative;
    text-align: center;
    padding-bottom: ${theme.spacers.normal};

    &::after {
      position: absolute;
      content: '';
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      width: calc(${theme.spacers.normal} * 2);
      height: 3px;
      background-color: ${theme.colors.main.primary};
      margin: 0 auto ${theme.spacers.normal} auto;
    }
  `}
`

export default Header
