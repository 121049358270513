import { useCallback } from 'react'

import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta'
import useCoursesPath from './useCoursesPath'
import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'

function useCourseSchema() {
  const { siteUrl, siteName, facebook } = useSiteMeta()
  const { getCoursePath } = useCoursesPath()

  const getSchema = useCallback(
    ({ slug, title, excerpt, price, promotion, coverImage }) => {
      const path = getCoursePath(slug)
      const image = toSingleSlash(
        `${siteUrl}/${coverImage.childImageSharp.fixed.src}`
      )

      return [
        {
          '@context': 'http://schema.org',
          '@type': 'Course',
          name: title,
          description: excerpt,
          url: toSingleSlash(`${siteUrl}/${path}`),
          image,
          provider: {
            '@type': 'Organization',
            name: siteName,
            sameAs: facebook,
          },
          inLanguage: 'th-TH',
        },
        // {
        //   '@context': 'http://schema.org',
        //   '@type': 'Product',
        //   name: title,
        //   description: excerpt,
        //   image,
        //   brand,
        //   offers: {
        //     price: promotion || price,
        //     priceCurrency: 'THB',
        //   },
        //   aggregateRating: {
        //     '@type': 'AggregateRating',
        //     bestRating: 5,
        //     ratingValue: 5,
        //     ratingCount: 1,
        //     worstRating: 5,
        //   },
        //   sku: slug,
        // },
      ]
    },
    [siteUrl, siteName, facebook, getCoursePath]
  )

  return getSchema
}

export default useCourseSchema
