import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import styled, { css } from 'styled-components';
import { up } from 'styled-breakpoints';

import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash';
import SEO from '@babelcoder/gatsby-theme-base/src/components/SEO';
import PageTitle from '@babelcoder/gatsby-theme-base/src/components/PageTitle';
import Pagination from '@babelcoder/gatsby-theme-base/src/components/Pagination';
import useSiteMeta from '@babelcoder/gatsby-theme-base/src/hooks/useSiteMeta';
import useCourseSchema from '../../../hooks/useCourseSchema';
import useCoursesPath from '../../../hooks/useCoursesPath';
import CourseItem from './CourseItem';

const Wrapper = styled.div`
  ${({ theme }) => css`
    display: flow-root;
    flex: 1;
    padding: ${theme.spacers.normal};
    background-color: ${theme.colors.neutral.gray100};
  `}
`;

const Container = styled.div`
  ${({ theme }) => css`
    max-width: 1200px;
    margin: 0 auto;

    ${up('small')} {
      padding: 0 ${theme.spacers.large};
    }
  `}
`;

const CourseList = styled.section`
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: ${({ theme }) => theme.spacers.normal};

  ${up('xlarge')} {
    grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  }
`;

function Courses({ courses, currentPage, totalPages }) {
  const { creatorImage } = useStaticQuery(graphql`
    query {
      creatorImage: imageSharp(original: { src: { regex: "/creator/" } }) {
        fixed(width: 30, height: 30) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `);
  const { basePath } = useCoursesPath();
  const { siteName, siteUrl } = useSiteMeta();
  const getSchema = useCourseSchema();
  const title = `คอร์สทั้งหมดของ ${siteName}`;
  const desc = `คอร์สสอนการโปรแกรมภาษาต่าง ๆ และแนวคิดที่เกี่ยวข้องของ ${siteName}`;
  const path = `${basePath}${currentPage === 1 ? '' : `/p/${currentPage}`}`;
  const schema = {
    '@context': 'http://schema.org',
    '@type': 'WebPage',
    name: title,
    description: desc,
    url: toSingleSlash(`${siteUrl}/${path}`),
    sameAs: 'https://facebook.com/babelcoder',
    inLanguage: 'th-TH',
    mainEntity: courses.map(({ node: course }) => getSchema(course)),
  };

  return (
    <>
      <SEO
        title={title}
        type="article"
        urlPath={path}
        desc={desc}
        schema={schema}
      ></SEO>
      <Wrapper>
        <Container>
          <PageTitle>คอร์สอบรมทั้งหมด</PageTitle>
          <CourseList>
            {courses.map(({ node }) => (
              <CourseItem
                key={node.slug}
                course={node}
                creatorImage={creatorImage}
              ></CourseItem>
            ))}
          </CourseList>
          {totalPages > 1 && (
            <Pagination
              basePath={basePath}
              currentPage={currentPage}
              totalPages={totalPages}
            ></Pagination>
          )}
        </Container>
      </Wrapper>
    </>
  );
}

export default Courses;
