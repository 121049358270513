import { graphql, useStaticQuery } from 'gatsby'

import toSingleSlash from '@babelcoder/gatsby-theme-base/src/utils/to-single-slash'

function useAuthorAndPublisherSchema() {
  const {
    logo,
    authorLogo,
    site: {
      siteMetadata: { siteName, siteUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
          siteUrl
        }
      }
      logo: imageSharp(original: { src: { regex: "/logo/" } }) {
        fixed(width: 120, height: 120) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
      authorLogo: imageSharp(original: { src: { regex: "/author/" } }) {
        fixed(width: 100, height: 100) {
          ...GatsbyImageSharpFixed_withWebp
        }
      }
    }
  `)

  return {
    author: {
      '@type': 'Person',
      name: 'Nuttavut Thongjor',
      image: {
        '@type': 'ImageObject',
        url: toSingleSlash(`${siteUrl}/${authorLogo.fixed.src}`),
        width: 100,
        height: 100,
      },
    },
    publisher: {
      '@type': 'Organization',
      name: siteName,
      logo: {
        '@type': 'ImageObject',
        url: toSingleSlash(`${siteUrl}/${logo.fixed.src}`),
        width: 120,
        height: 120,
      },
    },
  }
}

export default useAuthorAndPublisherSchema
