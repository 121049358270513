import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, useStaticQuery } from 'gatsby'

import useAuthorAndPublisherSchema from '../hooks/useAuthorAndPublisherSchema'
import addTrailingSlash from '../utils/add-trailing-slash'

function BaseSEO({
  title,
  type,
  urlPath,
  coverImageWidth,
  coverImageHeight,
  coverImageURL,
  desc,
  schema = {},
  ignoreAuthorAndPublisher = false,
}) {
  const {
    site: {
      siteMetadata: { siteName, facebook, locale, facebookAppID, siteUrl },
    },
  } = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteName
          facebook
          locale
          facebookAppID
          siteUrl
        }
      }
    }
  `)
  const authorAndPublisher = useAuthorAndPublisherSchema()
  const mergedSchema = ignoreAuthorAndPublisher
    ? schema
    : {
        ...schema,
        ...authorAndPublisher,
      }

  return (
    <Helmet htmlAttributes={{ lang: 'th' }}>
      <meta property="og:site_name" content={siteName} />
      <meta property="og:locale" content={locale} />
      <meta property="fb:app_id" content={facebookAppID} />
      <meta property="article:author" content={facebook} />
      <meta property="article:publisher" content={facebook} />
      <title>{title}</title>
      <meta
        property="og:url"
        content={addTrailingSlash(`${siteUrl}${urlPath}`)}
      />
      {type && <meta property="og:type" content={type} />}
      <meta property="og:title" content={title} />
      {coverImageWidth && (
        <meta property="og:image:width" content={coverImageWidth} />
      )}
      {coverImageHeight && (
        <meta property="og:image:height" content={coverImageHeight} />
      )}
      <link rel="canonical" href={addTrailingSlash(`${siteUrl}${urlPath}`)} />
      <meta name="description" content={desc} />
      <meta property="og:description" content={desc} />
      {coverImageURL && (
        <meta property="og:image" content={`${siteUrl}${coverImageURL}`} />
      )}
      <script type="application/ld+json">{JSON.stringify(mergedSchema)}</script>
    </Helmet>
  )
}

export default BaseSEO
